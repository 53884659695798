<template>
  <div>
    <section class="publicfund ">
      <div class="publicfund_cont">
        <div class="title_">
          <router-link :to="{name:'GetMoney2'}" class="iconfont arrleft"
            >&#xe665;</router-link
          >
          惠金宝
        </div>
        <div class="side_cont">
          <ProgressBar :sideData="side" />
        </div>
        <div class="solids_"></div>
        <div class="mytrue">
          <span class="iconfont">&#xe658;</span>
        </div>
        <h3>交易成功</h3>
        <div class="mytext">
          <div class="graytext">
            <div>金额</div>
            <div>类型</div>
            <div>交易</div>
            <div>转出方式</div>
            <div>预计到账时间</div>
          </div>
          <div class="content">
            <div>
              <span class="contentmoney">{{ numprice || "--" }}</span
              >元
            </div>
            <div>提现</div>
            <div class="bank">
              <img src="@/static/img/my/bank_icon.png" alt="" />
              <span>银行卡支付</span>
            </div>
            <div>
              {{ banckname }}<span>({{ lastnum }})</span>
            </div>
            <div>{{ date }}</div>
          </div>
        </div>
        <a class="mybtn" @click="go">去惠金宝查看</a>
      </div>
    </section>
  </div>
</template>
<script>
import ProgressBar from "@/components/ProgressBar";
export default {
  name: "GetMoneyS3",
  components: { ProgressBar },
  data() {
    return {
      side: [
        { title: "填写提现信息", type: 1 },
        { title: "输入交易密码", type: 1 },
        { title: "交易结果", type: 2 },
      ],
      numprice: "",
      banckname: "",
      lastnum: "",
      date: "",
    };
  },
  mounted() {
    this.numprice = sessionStorage.getItem("numprice");
    let banckdata = JSON.parse(localStorage.getItem("banckdata"));
    this.banckname = banckdata.bankName;
    this.lastnum = banckdata.banckNo.substr(-4, 4);
    this.date = new Date().toLocaleDateString();
  },
  methods: {
    go() {
      this.$router.push({name:'Hui'});
    },
  },
  destroyed() {
    sessionStorage.clear("numprice");
    // localStorage.clear("banckdata");
  },
};
</script>
<style lang="less" scoped>
::-webkit-input-placeholder {
  color: #a5a5a5;
  font-size: 14px;
}
.profitac {
  color: #e45247 !important;
}
.profit {
  color: #01b834 !important;
}
.publicfund {
  padding-bottom: 40px;
  min-height: 600px;
  display: flex;
  .publicfund_cont {
    background: #fff;
    min-height: 1290px;
    width: 930px;
    .title_ {
      padding: 21px 40px 0px 43px;
      height: 60px;
      font-weight: 500;
      font-size: 20px;
      color: #1f1f1f;
      .arrleft {
        margin-right: 14px;
        color: #bdc0cb;
      }
    }
    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }
    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }
    .mytrue {
      width: 16px;
      height: 16px;
      color: #02d33d;
      transform: scale(7.5);
      margin: 0 auto;
      margin-top: 101px;
    }
    h3 {
      text-align: center;
      margin-top: 90px;
      font-size: 20px;
      font-weight: 400;
    }
    .mytext {
      width: 300px;
      height: 300px;
      margin: 0 auto;
      margin-top: 60px;
      display: flex;
      .graytext {
        width: 100px;
        font-size: 16px;
        color: #a5a5a5;
        text-align: right;
        div {
          margin-bottom: 38px;
        }
      }
      .content {
        text-align: left;
        margin-left: 20px;
        font-size: 18px;
        .bank {
          display: flex;
          align-items: center;
          img {
            margin-right: 9px;
          }
        }
        div {
          &:first-child {
            margin-bottom: 32px;
            span {
              color: #d43f3f;
            }
          }
          &:nth-child(2) {
            margin-bottom: 34px;
          }
          &:nth-child(3) {
            margin-bottom: 34px;
          }
          &:nth-child(4) {
            margin-bottom: 40px;
            span {
              &:nth-child(2) {
                color: #a5a5a5;
              }
            }
          }
        }
      }
    }
    .mybtn {
      width: 240px;
      height: 48px;
      display: block;
      margin: 0 auto;
      background: linear-gradient(101deg, #f7d9b7 0%, #eabf96 100%);
      border-radius: 2px;
      text-align: center;
      line-height: 48px;
      color: #b88141;
      font-size: 16px;
      margin-top: 65px;
    }
  }
}
</style>
